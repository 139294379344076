import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-4 px-10 py-1" }
const _hoisted_2 = { class: "flex space-x-4 mb-3" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { class: "relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ol", _hoisted_2, [
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, { to: "/" }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Home")
          ])),
          _: 1
        })
      ]),
      _cache[2] || (_cache[2] = _createElementVNode("li", null, ">", -1)),
      _createElementVNode("li", _hoisted_3, [
        _createVNode(_component_router_link, { to: "performance-world-map" }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode(" RE Performance World Map ")
          ])),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (!$setup.isLoadingWordMapEmissionGetter)
        ? (_openBlock(), _createBlock($setup["Maps"], {
            key: 0,
            data: $setup.worldMapEmissionGetter.ScopeTwoEmissions,
            linearColor: $setup.scope,
            class: _normalizeClass(_ctx.styles['map-size'])
          }, null, 8, ["data", "class"]))
        : _createCommentVNode("", true),
      (!$setup.isLoadingWordMapEmissionGetter)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["inputfield line-clamp-1 absolute bottom-6", _ctx.styles['no-facility']])
          }, _cache[3] || (_cache[3] = [
            _createElementVNode("button", null, [
              _createElementVNode("div", { class: "w-3 h-3 bg-cafe-gray rounded" })
            ], -1),
            _createElementVNode("label", {
              for: "scope",
              class: "cursor-pointer"
            }, " No Facility ", -1)
          ]), 2))
        : _createCommentVNode("", true)
    ])
  ]))
}