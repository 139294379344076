import { defineComponent as _defineComponent } from 'vue'
import Maps from "@/components/AnyChart/Maps.vue";
import EmissionModule from "@ems/containers/Emission/Emission.module";
import ScopeTwoEmissionModule from "@ems/containers/ScopeTwoEmission/ScopeTwoEmission.module";
import { computed, onMounted, ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'SA',
  setup(__props, { expose: __expose }) {
  __expose();

const scope = { start: "#CACDC9", end: "#2A7119" };

const isLoadingWordMapEmissionGetter = computed(
  () => EmissionModule.isLoadingWordMapEmissionGetter
);
const worldMapEmissionGetter = computed(
  () => EmissionModule.worldMapEmissionGetter
);

onMounted(() => {
  EmissionModule.getWorldMapEmission({
    Year:
      ScopeTwoEmissionModule.dataScopeTwoEmissionGetter.Year ||
      new Date().getFullYear(),
  });
});

const __returned__ = { scope, isLoadingWordMapEmissionGetter, worldMapEmissionGetter, Maps, get EmissionModule() { return EmissionModule }, get ScopeTwoEmissionModule() { return ScopeTwoEmissionModule }, computed, onMounted, ref }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})